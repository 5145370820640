export const locale = {
    lang: "fr",
    data: {
        notifications: {
            errors: {
                recoveryNotAvalaible:
                    "La gestion des récupération est désactivée",
                loanNotAvalaible: "La gestion des prêts est désactivée",
                feeNotAvalaible: "La gestion des frais est désactivée",
                exitNotAvalaible: "La gestion des sorties est désactivée",
                missionNotAvaliable: "La gestion des missions est désactivée",
                unauthorized: "Adresse mail et/ou  mot de passe incorrecte",
                payAccess: "Pas de fiche de paie disponible ",
            },
        },
        NAV: {
            APPLICATIONS: "Programlar",

            LEAVE_RECOVERY: {
                TITLE: "Congé & Récup",
                BADGE: "",
            },
            LEAVE: {
                TITLE: "Congés",
                BADGE: "",
            },
            RECOVRY: {
                TITLE: "Récuperations",
                BADGE: "",
            },

            EMPLOYEE_PERSONAL_INFORMATIONS: {
                TITLE: "Informations Personnelles",
                BADGE: "",
            },
            POINTAGE: {
                TITLE: "Temps",
                BADGE: "",
            },

            ABSENCES: {
                TITLE: "Absences",
                BADGE: "",
            },

            ABSENCE_EXIT: {
                TITLE: "Absences & Sorties",
                BADGE: "",
            },

            EXIT: {
                TITLE: "Sorties",
                BADGE: "",
            },

            ABSENCE: {
                TITLE: "Absences",
                BADGE: "",
            },
            LOAN: {
                TITLE: "Prêts",
                BADGE: "",
            },
            PAYSLIP: {
                TITLE: "Paies",
                BADGE: "",
            },
            PAYS_LOAN: {
                TITLE: "Paie",
                BADGE: "",
            },

            EMPLOYEE_PROFESSIONAL_INFORMATIONS: {
                TITLE: "Mon profile detaillé",
                BADGE: "",
            },
            PLANNINGS: {
                TITLE: "Mon planning",
                BADGE: "",
            },
            VIEW_POINTING: {
                TITLE: "Mes pointages",
                BADGE: "",
            },
            ENTRIES: {
                TITLE: "Pointer une entrée",
                BADGE: "",
            },
            EXITS: {
                TITLE: "Pointer une sortie",
                BADGE: "",
            },
            FEE: {
                TITLE: "Frais",
                BADGE: "",
            },
            PAYS_LOAN_FEE: {
                TITLE: "Paie ",
                BADGE: "",
            },
            PAYS_FEE: {
                TITLE: "Paie ",
                BADGE: "",
            },
            MISSIONS: {
                TITLE: "Mission",
                BADGE: "",
            },
        },
        errors: {
            createFolder: "Veuillez d'abord créer un dossier",
            standardErrorMessagePointing:
                "Vérifier la validité du code QR auprès de votre entreprise",
            standardErrorMessage403:
                "Vous n'êtes pas autorisé a accéder a se module, veuillez contactez votre administrateur",
            standardErrorMessage400: "Une mauvaise requête est lancée",
            standardErrorMessage404:
                "Une erreur de connexion au serveur s'est produite, veuillez réessayer plus tard",
            standardErrorMessage500:
                "Une erreur de connexion au serveur s'est produite, veuillez réessayer plus tard",
            E008:
                "cet utilisateur est déja administrateur/utilisateur dans ce compte client",
            E009: "Utilisateur backoffice",
            E010: "Votre mot de passe actuel est incorrect",
            E014:
                "Votre inscription a échouée, veuillez réessayer s'il vous plait",
            E116: "Intersection des dates d'absences",
            E132: "Fichier corrompu",
            E136:
                "Il n'existe plus de stockage pour joindre de justificatif à une demande d'absence",
            E143: "L'année concernée par le congé est requise",
            E147: "Jour non valide",
            E148:
                "Vous ne serez pas en poste durant la période choisie pour la demande de congé",
            E149:
                "Votre demande d'annulation pour cette absence vient trop tard",
            E150: "Nombre de jours max dépassé",
            E151: "Nombre max d'heures dépassé",
            E152: "Nombre ma d'heures par jour dépassé",
            E157: "Champ autre raison est obligatoire",
            E158:
                "Il vous faut au minimum une heure pour une demande d'absence",
            E166: "Imposible de mettre une date supérieure à la date de sortie",
            E171: "Date début/fin ne peuvent pas êtres un jour de week-end",
            E197:
                "L'heure de la date de retour de sortie ne peut pas être supérieure à la durée légale de travail par jour",
            errorParams: "Adresse e-mail et/ou mot de passe erroné",
            E328: "Cet employé ne posséde pas encore de rubriques",
            E332: "Champ exercice obligatoire",
            E333: "Exercice(s) non valide(s)",
            E030: "Compte déjà activé",
            E334:
                "La période sélectionnée dépasse les jours restants auxquels vous avez droit",
            E339:
                "Vous ne serez pas en poste durant la période choisie pour la demande de congé",
            E340: "Votre demande d'annulation pour ce congé vient trop tard",
            E316: "Vous ne pouvez plus pointer une Entrée/Sortie",
            E347:
                "Vous avez déjà effectué une demande de congé pour la période choisie",
            E322: "Vous ne pouvez pas pointer une sortie avant une entrée",
            E323: "Chevauchement d'entrée/sortie",
            E344: "Vous n'êtes pas autorisé à pointer des Entrées/Sorties",
            E359: "Vous n'êtes pas en poste pour pointer une Entrée/Sortie",
            E360: "Aucune entrée pour cette sortie",
            E370: " Vous ne pouvez pas pointer Deux entrées successives",
            E372: "Vous ne pouvez pas effectuer cette demande",
            E373:
                "Vous avez deja une demande de récupération incluant cette date",
            E374:
                "Votre demande d'annulation pour cette récupération vient trop tard",
            E380: "Vous avez déjà une demande de prêt en cours",
            E999:
                " Votre compte a été bloqué, veuillez réessayer dans quelques minutes",
            E321: "Vous avez dépasser votre temps de travailler journalier",
            E087: "Votre compte a déjà été réinitialisé",
            sendNotifError:
                "Une erreur s'est produite lors de l'inscription au service de notification. Veuillez, s'il vous plaît, fermer et rouvrir l'application pour résoudre le problème.",
            pushNotificationsRegistrationError:
                "Erreur d'enregistrement au notifications push",
            pushNotificationsUserDeniedPermissions:
                "Notifications push Permissions refusées par l'utilisateur",
        },
    },
};
