import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { RequestInterceptorService } from './request-interciptor.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private requestInterceptorService: RequestInterceptorService,
    private apiService: ApiService
  ) { }

  /**
   * Retrieves all notifications.
   *
   * @return {Observable<any>} The observable of the HTTP GET request for notifications.
  */
  getAllNotifications(): Observable<any> {
    return this.apiService.httpGet(environment.ENDPOINTS.NOTIFICATION_URL);
  }

  /**
   * A function that marks a notification as read.
   *
   * @param {type} id - the ID of the notification to mark as read
   * @return {type} the result of the HTTP POST request
  */
  markNotificationAsRead(id) {
    const NOTIF_ID = id !== null && id !== undefined ? `/${id}` : ``;
    const url = environment.ENDPOINTS.NOTIFICATION_URL + NOTIF_ID;
    return this.apiService.httpPost(url, {});
  }
}
