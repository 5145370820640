<div *ngIf="inMobile" class="top-panel accent" [ngClass]="{'disc-open': open}">
    <div *ngFor="let link of topLinks" class="div-class">
        <a *ngIf="showTopItems" [ngStyle]="{display: open ? 'flex' : 'none'}" class="items-top"
            routerLinkActive="router-link-active">
            <button mat-icon-button class="items-top" (click)="closeDisc()" [class.entry]="link.title==='Entree'"
                [routerLink]="[link.path]" [class.live]="link.title==='Sortie'"
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">
                <mat-icon>{{link.icon}}</mat-icon>
            </button>
            <span class="link-title-top" [class.entry]="link.title==='Entree'" [class.live]="link.title==='Sortie'"
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">{{link.title}}</span>
        </a>
    </div>
</div>
<div *ngIf="inMobile" class="top-panel2 accent" [ngClass]="{'disc-open': open1}">
    <div *ngFor="let link of leaveLinks" class="div-class">
        <div *ngIf="showTopItems1" [ngStyle]="{display: open1 ? 'flex' : 'none'}" class="items-top"
            routerLinkActive="router-link-active">
            <button mat-icon-button class="items-top" (click)="closeDisc1();" [class.entry]="link.title==='Entree'"
                [routerLink]="[link.path]" [class.live]="link.title==='Sortie'"
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">
                <mat-icon>{{link.icon}}</mat-icon>
            </button>
            <span class="link-title-top" [class.entry]="link.title==='Entree'" [class.live]="link.title==='Sortie'"
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">{{link.title}}</span>
        </div>
    </div>

</div>
<div *ngIf="inMobile" class="top-panel2 accent" [ngClass]="{'disc-open': open2}">
    <div *ngFor="let link of financeLinks" class="div-class">
        <a *ngIf="showTopItems2" [ngStyle]="{display: open2 ? 'flex' : 'none'}" class="items-top"
            routerLinkActive="router-link-active">
            <button mat-icon-button class="items-top" (click)="closeDisc2()" [class.entry]="link.title==='Entree'"
                [routerLink]="[link.path]" [class.live]="link.title==='Sortie'"
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">
                <mat-icon>{{link.icon}}</mat-icon>
            </button>
            <span class="link-title-top" [class.entry]="link.title==='Entree'" [class.live]="link.title==='Sortie'"
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">{{link.title}}</span>
        </a>
    </div>

</div>
<div *ngIf="inMobile" class="top-panel2 accent" [ngClass]="{'disc-open': open3}">
    <div *ngFor="let link of absencesLink " class="div-class">
        <a *ngIf="showTopItems3" [ngStyle]="{display: open3 ? 'flex' : 'none'}" class="items-top"
            routerLinkActive="router-link-active">
            <button mat-icon-button class="items-top" (click)="closeDisc3();" [class.entry]="link.title==='Entree'"
                [routerLink]="[link.path]" [class.auther]="true">
                <mat-icon>{{link.icon}}</mat-icon>
            </button>
            <span class="link-title-top" [class.entry]="link.title==='Entree'"
                [class.auther]="true">{{link.title}}</span>
        </a>
    </div>
</div>
<div *ngIf="inMobile" class="top-panel2 accent" [ngClass]="{'disc-open': open4}">
    <div *ngFor="let link of optionsLinks " class="div-class">
        <a *ngIf="showTopItems4" [ngStyle]="{display: open4 ? 'flex' : 'none'}" 
            routerLinkActive="router-link-active" class="items-top">
            <button mat-icon-button [routerLink]="[link.path]" class="items-top" (click)="closeDisc4();" [class.entry]="link.title==='Entree'"
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">
                <mat-icon>{{link.icon}}</mat-icon>
            </button>
            <span class="link-title-top" [class.entry]="link.title==='Entree'" 
                [class.auther]="link.title!=='Sortie' && link.title!=='Entree'">{{link.title}}</span>
        </a>
    </div>
</div>
<mat-toolbar class="footer-container" [ngStyle]="{height: inMobile ? '60px' : '45px'}">
    <div *ngIf="!inMobile" class="link">
        <a mat-button href="https://rh-partner.com" rel="noopener noreferrer" target="_blank" class="url-style"> Portail Employé
            {{currentYear}}. Powered
            by BP </a>
    </div>

    <div *ngIf="inMobile" class="navigator-links">
        <div class="links" *ngFor="let link of links">
            <a *ngIf="link.title !== 'pointage' && link.title !== 'Repos' && link.title!== 'Finance' && link.title!=='Absences'
            && link.title !=='Options'"
                [routerLink]="[link.path]" routerLinkActive="router-link-active" class="links-item">
                <button mat-icon-button class="items" (click)="closeDisc() ;closeDisc1();closeDisc2();closeDisc3()">
                    <mat-icon>{{link.icon}}</mat-icon>
                </button>
                <span class="link-title">{{link.title}}</span>
            </a>

            <a *ngIf="link.title === 'pointage'">
                <button mat-mini-fab color="accent" class="pointing-btn" (click)="openDisc()">
                    <mat-icon>fingerprint</mat-icon>
                </button>
            </a>
            <div *ngIf="link.title === 'Absences' && exitAvaliable"
                [ngClass]="{'router-link-active': isActiveLink(link.title)}" class="links-item">
                <button mat-icon-button class="items" (click)="openDisc3()">
                    <mat-icon>{{link.icon}}</mat-icon>
                </button>
                <span class="link-title">{{link.title}}</span>
            </div>
            <div *ngIf="link.title === 'Absences' && !exitAvaliable" [routerLink]="[absencesLink[absenceIndex()].path]"
                routerLinkActive="router-link-active" class="links-item">
                <button mat-icon-button class="items" [routerLink]="[absencesLink[absenceIndex()].path]"
                    (click)="closeDisc() ;closeDisc1();closeDisc2()">
                    <mat-icon>{{absencesLink[absenceIndex()].icon}}</mat-icon>
                </button>
                <span class="link-title">{{absencesLink[absenceIndex()].title}}</span>
            </div>
            <div *ngIf="link.title === 'Repos' && recoveryAvalaible"
                [ngClass]="{'router-link-active': isActiveLink(link.title) }" class="links-item">
                <button mat-icon-button class="items" (click)="openDisc1()">
                    <mat-icon>{{link.icon}}</mat-icon>
                </button>
                <span class="link-title">{{link.title}}</span>
            </div>
            <div *ngIf="link.title === 'Repos' && !recoveryAvalaible" [routerLink]="[leaveLinks[leaveIndex()].path]"
                routerLinkActive="router-link-active" class="links-item">
                <button mat-icon-button class="items" [routerLink]="[leaveLinks[leaveIndex()].path]"
                    (click)="closeDisc() ;closeDisc3();closeDisc2()">
                    <mat-icon>{{leaveLinks[leaveIndex()].icon}}</mat-icon>
                </button>
                <span class="link-title">{{leaveLinks[leaveIndex()].title}}</span>
            </div>

            <div *ngIf="link.title === 'Finance' && (loanAvalaible || feesAvaliable)"
                [ngClass]="{'router-link-active': isActiveLink(link.title)}" class="links-item">
                <button mat-icon-button class="items" (click)="openDisc2()">
                    <mat-icon>{{link.icon}}</mat-icon>
                </button>
                <span class="link-title">{{link.title}}</span>
            </div>
            <a *ngIf="link.title === 'Finance' && !(loanAvalaible || feesAvaliable)" [routerLink]="[financeLinks[payIndex()].path]"
                routerLinkActive="router-link-active" class="links-item">
                <button mat-icon-button class="items" [routerLink]="[financeLinks[payIndex()].path]"
                    (click)="closeDisc() ;closeDisc1();closeDisc3()">
                    <mat-icon>{{financeLinks[payIndex()].icon}}</mat-icon>
                </button>
                <span class="link-title">{{financeLinks[payIndex()].title}}</span>
            </a>
             <div *ngIf="link.title === 'Options' && missionAvaliable"
            [ngClass]="{'router-link-active': isActiveLink(link.title)}" class="links-item">
            <button mat-icon-button class="items" (click)="openDisc4()">
                <mat-icon>{{link.icon}}</mat-icon>
            </button>
            <span class="link-title">{{link.title}}</span>
             </div>
            <div *ngIf="link.title === 'Options' && !missionAvaliable"
            [routerLink]="[optionsLinks[missionIndex()].path]" routerLinkActive="router-link-active" class="links-item">
                <button mat-icon-button class="items" [routerLink]="[optionsLinks[missionIndex()].path]"(click)="closeDisc() ;closeDisc1();closeDisc3();closeDisc4()">
                    <mat-icon>{{optionsLinks[missionIndex()].icon}}</mat-icon>
                </button>
                <span class="link-title">{{optionsLinks[missionIndex()].title}}</span>
            </div>
        </div>
      
    </div>
</mat-toolbar>