@use '@angular/material' as mat;
@import "@fuse/scss/fuse.scss";

app-fuse-sidebar {

    &.navbar-fuse-sidebar {
        overflow: hidden;

        &.folded:not(.unfolded) {

            navbar {

                navbar-vertical-style-2 {

                    .navbar-header {
                        padding: 0 13px;

                        .logo {

                            .logo-text {
                                opacity: 0;
                                transition: opacity 200ms ease;
                            }
                        }
                    }

                    .navbar-content {

                        // Material 2 specific style
                        .material2 {

                            .nav-item {

                                .nav-link {
                                    border-radius: 20px;
                                    margin: 0 12px;
                                    padding: 0 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

navbar {

    &.vertical-style-2 {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        height: 100%;

        &.right-navbar {

            .toggle-sidebar-opened {

                mat-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }

    navbar-vertical-style-2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .navbar-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 64px;
            min-height: 64px;
            padding: 0 16px 0 24px;
            transition: padding 200ms ease;
            @include mat.elevation(1);

            .logo {
                display: flex;
                align-items: center;

                .logo-icon {
                    width: 38px;
                    height: 38px;
                }

                .logo-text {
                    margin-left: 8px;
                    font-size: 20px;
                    font-weight: 300;
                    letter-spacing: 0.4px;
                }
            }
        }

        .navbar-content {
            flex: 1 1 auto;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

    }
}
