import { Component, OnInit } from "@angular/core";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseNavigation } from "@fuse/types";
import {
    absence,
    absenceExit,
    leave,
    leaveRecovery,
    pay,
    payFee,
    payLoan,
    payLoanFee,
    mission,
} from "app/navigation/navigation";
import { UserService } from "../services/user.service";
import { StorageUtils } from "../shared/storage-utils";
import { ActivatedRoute } from "@angular/router";
import { Platform } from "@angular/cdk/platform";
import { PushNotificationsService } from "../services/push-notifications.service";

@Component({
    selector: "app-pages",
    templateUrl: "./pages.component.html",
    styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit {
    data: any;

    constructor(
        private route: ActivatedRoute,
        private userSeervice: UserService,
        private platform: Platform,
        private pushNotificationsService: PushNotificationsService,
        private fuseNavigationService: FuseNavigationService
    ) {}

    ngOnInit(): void {
        this.route.data.subscribe(({ data }) => {
            StorageUtils.setUser(data.userData);
            StorageUtils.setConfig(data.configData);
            this.setConfig(data.configData);
        });

        if (this.platform.ANDROID || this.platform.IOS) {
            this.pushNotificationsService.initPushNotifications();
        }
    }

    setConfig(data: any) {
        const recoveryAvalaible = data.enableRecoveryManagement;
        const loanAvalaible = data.enableLoanManagement;
        const exitAvalaible = data.enableExitManagement;
        const missionsAvalaible = data.enableMissionManagement;
        const feeAvalaible = data.enableFeesManagement;
        const principalItem: FuseNavigation = this.fuseNavigationService.getNavigationItem(
            "applications"
        );
        recoveryAvalaible
            ? principalItem.children.splice(
                  principalItem.children.findIndex((e) => e.id === "leaves"),
                  1,
                  leaveRecovery
              )
            : principalItem.children.splice(
                  principalItem.children.findIndex((e) => e.id === "leaves"),
                  1,
                  leave
              );
        exitAvalaible
            ? principalItem.children.splice(
                  principalItem.children.findIndex((e) => e.id === "absences"),
                  1,
                  absenceExit
              )
            : principalItem.children.splice(
                  principalItem.children.findIndex((e) => e.id === "absences"),
                  1,
                  absence
              );
        if (missionsAvalaible) {
            const itemAvalaible = principalItem.children.find(
                (e) => e.id === "missions"
            );
            if (!itemAvalaible) {
                principalItem.children.splice(4, 0, mission);
            }
        }
        principalItem.children.splice(
            principalItem.children.findIndex((e) => e.id === "payslip"),
            1,
            pay
        );
        if (loanAvalaible && feeAvalaible) {
            principalItem.children.splice(
                principalItem.children.findIndex((e) => e.id === "payslip"),
                1,
                payLoanFee
            );
        } else if (loanAvalaible) {
            principalItem.children.splice(
                principalItem.children.findIndex((e) => e.id === "payslip"),
                1,
                payLoan
            );
        } else if (feeAvalaible) {
            principalItem.children.splice(
                principalItem.children.findIndex((e) => e.id === "payslip"),
                1,
                payFee
            );
        } else {
            principalItem.children.splice(
                principalItem.children.findIndex((e) => e.id === "payslip"),
                1,
                pay
            );
        }
        this.fuseNavigationService.updateNavigationItem(
            "applications",
            principalItem
        );
    }
}
