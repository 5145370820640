import { FuseNavigation, FuseNavigationItem } from '@fuse/types';
import { StorageUtils } from 'app/main/shared/storage-utils';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        translate: '',
        type: 'group',
        children: [
            {
                id: 'employee-personal-informations',
                title: 'employee-personal-informations',
                translate: 'NAV.EMPLOYEE_PERSONAL_INFORMATIONS.TITLE',
                type: 'item',
                url: '/employee-personal-informations',
                hidden: true,

            },
            {
                id: 'employee-professional-infomations',
                title: 'Mon profile',
                translate: 'NAV.EMPLOYEE_PROFESSIONAL_INFORMATIONS.TITLE',
                type: 'item',
                icon: 'people_alt',
                url: '/employee-professional-infomations',

            },
            {
                id: 'leaves',
                title: 'leave',
                translate: 'NAV.LEAVE.TITLE',
                type: 'item',
                icon: 'leave',
                svgIcon: true,
                url: 'leave',
            },
            {
                id: 'absences',
                title: 'Absences',
                translate: 'NAV.ABSENCE.TITLE',
                type: 'item',
                icon: 'absence',
                svgIcon: true,
                url: '/absence',
            },
            {
                id: 'payslip',
                title: 'payslip',
                translate: 'NAV.PAYSLIP.TITLE',
                type: 'item',
                icon: 'payroll',
                svgIcon: true,
                url: '/payslip',
            },
            {
                id: 'pointing',
                title: 'Pointing',
                translate: 'NAV.POINTAGE.TITLE',
                type: 'collapsable',
                icon: 'time',
                svgIcon: true,
                children: [
                    {
                        id: 'plannings',
                        title: 'plannings',
                        translate: 'NAV.PLANNINGS.TITLE',
                        type: 'item',
                        icon: '',
                        url: '/pointing/plannings',
                    },
                    {
                        id: 'my-pointings',
                        title: 'Pointing',
                        translate: 'NAV.VIEW_POINTING.TITLE',
                        type: 'item',
                        icon: '',
                        url: '/pointing/my-pointings',
                    },
                    {
                        id: 'entry',
                        title: 'Entries',
                        translate: 'NAV.ENTRIES.TITLE',
                        type: 'item',
                        icon: '',
                        url: '/pointing/entry',
                    },
                    {
                        id: 'exit',
                        title: 'Exits',
                        translate: 'NAV.EXITS.TITLE',
                        type: 'item',
                        icon: '',
                        url: '/pointing/exit',
                    },
                ]
            },
        ]
    }
];

export const leave: FuseNavigationItem = {
    id: 'leaves',
    title: 'leave',
    translate: 'NAV.LEAVE.TITLE',
    type: 'item',
    icon: 'leave',
    svgIcon: true,
    url: 'leave',
};
export const leaveRecovery: FuseNavigationItem = {
    id: 'leaves',
    title: 'leave_recovery',
    translate: 'NAV.LEAVE_RECOVERY.TITLE',
    type: 'collapsable',
    icon: 'leave',
    svgIcon: true,
    children: [
        {
            id: 'leave',
            title: 'leave',
            translate: 'NAV.LEAVE.TITLE',
            type: 'item',
            icon: '',
            svgIcon: true,
            url: '/leave',
        },
        {
            id: 'recovery',
            title: 'recovery',
            translate: 'NAV.RECOVRY.TITLE',
            type: 'item',
            icon: '',
            svgIcon: true,
            url: '/recovery',
        },
    ]
};
export const pay: FuseNavigationItem = {
    id: 'payslip',
    title: 'payslip',
    translate: 'NAV.PAYSLIP.TITLE',
    type: 'item',
    icon: 'payroll',
    svgIcon: true,
    url: '/payslip',
};
export const payLoan: FuseNavigationItem = {
    id: 'payslip',
    title: 'paysloan',
    translate: 'NAV.PAYS_LOAN.TITLE',
    type: 'collapsable',
    icon: 'payroll',
    svgIcon: true,
    children: [
        {
            id: 'payslip',
            title: 'payslip',
            translate: 'NAV.PAYSLIP.TITLE',
            type: 'item',
            icon: '',
            url: '/payslip',
        },
        {
            id: 'loan',
            title: 'loan',
            translate: 'NAV.LOAN.TITLE',
            type: 'item',
            icon: '',
            url: '/loan'
        }
    ]
}
export const payFee: FuseNavigationItem = {
    id: 'payslip',
    title: 'paysfee',
    translate: 'NAV.PAYS_FEE.TITLE',
    type: 'collapsable',
    icon: 'payroll',
    svgIcon: true,
    children: [
        {
            id: 'payslip',
            title: 'payslip',
            translate: 'NAV.PAYSLIP.TITLE',
            type: 'item',
            icon: '',
            url: '/payslip',
        },
        {
            id: 'fee',
            title: 'fee',
            translate: 'NAV.FEE.TITLE',
            type: 'item',
            icon: '',
            url: '/fees'
        }
    ]
}
export const payLoanFee: FuseNavigationItem = {
    id: 'payslip',
    title: 'paysloanfee',
    translate: 'NAV.PAYS_LOAN_FEE.TITLE',
    type: 'collapsable',
    icon: 'payroll',
    svgIcon: true,
    children: [
        {
            id: 'payslip',
            title: 'payslip',
            translate: 'NAV.PAYSLIP.TITLE',
            type: 'item',
            icon: '',
            url: '/payslip',
        },
        {
            id: 'loan',
            title: 'loan',
            translate: 'NAV.LOAN.TITLE',
            type: 'item',
            icon: '',
            url: '/loan'
        },
        {
            id: 'fee',
            title: 'fee',
            translate: 'NAV.FEE.TITLE',
            type: 'item',
            icon: '',
            url: '/fees'
        }
    ]
}
export const absenceExit: FuseNavigation = {
    id: 'absences',
    title: 'absenceOutput',
    translate: 'NAV.ABSENCE_EXIT.TITLE',
    type: 'collapsable',
    icon: 'absence',
    svgIcon: true,
    children: [
        {
            id: 'absences',
            title: 'Absences',
            translate: 'NAV.ABSENCES.TITLE',
            type: 'item',
            url: '/absence',
        },
        {
            id: 'exits',
            title: 'exits',
            translate: 'NAV.EXIT.TITLE',
            type: 'item',
            url: '/exits',
        },
    ]

};
export const absence: FuseNavigation = {
    id: 'absences',
    title: 'Absences',
    translate: 'NAV.ABSENCE.TITLE',
    type: 'item',
    icon: 'absence',
    svgIcon: true,
    url: '/absence',
};
export const mission: FuseNavigationItem = {
    id: 'missions',
    title: 'missions',
    translate: 'NAV.MISSIONS.TITLE',
    type: 'item',
    icon: 'mission',
    svgIcon: true,
    url: '/missions',
};