import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageUtils } from 'app/main/shared/storage-utils';
import { UserService } from 'app/main/services/user.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    currentYear = new Date().getFullYear();
    absencesLink = [];
    inMobile: boolean;
    links = [];
    topLinks = [];
    leaveLinks = [];
    optionsLinks=[];
    financeLinks = [];
    pointing: boolean;
    open = false;
    open1 = false;
    open2 = false;
    open3 = false;
    open4 = false;
    absenceLink = '/absence';
    showTopItems: boolean;
    showTopItems1 = false;
    showTopItems2 = false;
    showTopItems3 = false;
    showTopItems4 = false;
    recoveryAvalaible: boolean;
    loanAvalaible: boolean;
    exitAvaliable: boolean;
    feesAvaliable: boolean;
    missionAvaliable:boolean;
    /**
     * Constructor
     */
    constructor(
        private platform: Platform, 
        private router: Router, 
        private route: ActivatedRoute,
    ) {
        if ((this.platform.ANDROID || this.platform.IOS)) {
            this.inMobile = true;
        }

        this.links = [

            { title: 'Options', icon: 'list', path: '/employee-professional-infomations' },
            { title: 'Repos', icon: 'calendar_today', path: '/recovery'  },
            { title: 'pointage', icon: 'date_range', path: '/pointing/my-pointings' },
            { title: 'Absences', icon: 'perm_contact_calendar', path: '/exits' },
            { title: 'Finance', icon: 'account_balance_wallet', path: 'loan' }
        ];

        this.topLinks = [
            { title: 'Pointages', icon: 'date_range', path: '/pointing/my-pointings' },
            { title: 'Entrée', icon: 'vertical_align_top', path: '/pointing/entry' },
            { title: 'Sortie', icon: 'vertical_align_bottom', path: '/pointing/exit' },
            { title: 'Planning', icon: 'today', path: '/pointing/plannings' }
        ];
        this.leaveLinks = [
            { title: 'Récupération', icon: 'today', path: '/recovery' },
            { title: 'Congés', icon: 'date_range', path: '/leave' },

        ];
        this.financeLinks = [
            { title: 'Prêt', icon: 'monetization_on', path: '/loan' },
            { title: 'Paie', icon: 'credit_card', path: '/payslip' },
            { title: 'Frais', icon: 'attach_money', path: '/fees' },
        ];
        this.absencesLink = [
            { title: 'Absence', icon: ' work_off', path: '/absence' },
            { title: 'Sortie', icon: 'exit_to_app', path: '/exits' },
        ];
        this.optionsLinks=[
            { title: 'Infos', icon: 'people_alt', path: '/employee-professional-infomations' },
            {title:'Missions',icon :'assignment_turned_in' ,path:'/missions'},

        ];
        document.getElementById('container-3').addEventListener('click', (event) => {
            if (this.open) {
                this.closeDisc();
            }
            if (this.open1) {
                this.closeDisc1();
            }
            if (this.open2) {
                this.closeDisc2();

            } if (this.open3) {
                this.closeDisc3();
            } if (this.open4) {
                this.closeDisc4();
            }
        });
        document.getElementById('container-3').addEventListener('touchstart', (event) => {
            if (this.open) {
                this.closeDisc();
            }
            if (this.open1) {
                this.closeDisc1();
            }
            if (this.open2) {
                this.closeDisc2();

            } if (this.open3) {
                this.closeDisc3();
            }
            if (this.open4) {
                this.closeDisc4();
            }
        });
    }
    ngOnInit(): void {

        this.route.data.subscribe(({data}) => {
            StorageUtils.setUser(data.userData)
            StorageUtils.setConfig(data.configData)

            this.recoveryAvalaible = StorageUtils.getConfig() && StorageUtils.getConfig().enableRecoveryManagement;
            this.loanAvalaible = StorageUtils.getConfig() && StorageUtils.getConfig().enableLoanManagement;
            this.exitAvaliable = StorageUtils.getConfig() && StorageUtils.getConfig().enableExitManagement;     
            this.feesAvaliable = StorageUtils.getConfig() && StorageUtils.getConfig().enableFeesManagement;
            this.missionAvaliable=StorageUtils.getConfig() && StorageUtils.getConfig().enableMissionManagement;
            
            this.setbottomBarItems();
        });


    }


    openDisc() {
        this.showTopItems = false;
        this.open = !this.open;
        setTimeout(() => {
            this.showTopItems = true;
        }, 250);
        this.closeDisc1();
        this.closeDisc2();
        this.closeDisc3();
        this.closeDisc4();

    }

    closeDisc() {
        this.open = false;
        this.showTopItems = false;
    }

    openDisc1() {
        this.showTopItems1 = false;
        this.open1 = !this.open1;
        setTimeout(() => {
            this.showTopItems1 = true;
        }, 250);
        this.closeDisc();
        this.closeDisc2();
        this.closeDisc3();
        this.closeDisc4();

    }

    closeDisc1() {
        this.open1 = false;
        this.showTopItems1 = false;
    }

    openDisc2() {
        this.showTopItems2 = false;
        this.open2 = !this.open2;
        setTimeout(() => {
            this.showTopItems2 = true;
        }, 250);
        this.closeDisc1();
        this.closeDisc();
        this.closeDisc3();
        this.closeDisc4();

    }

    closeDisc2() {
        this.open2 = false;
        this.showTopItems2 = false;

    }

    openDisc3() {
        this.showTopItems3 = false;
        this.open3 = !this.open3;
        setTimeout(() => {
            this.showTopItems3 = true;
        }, 250);
        this.closeDisc1();
        this.closeDisc();
        this.closeDisc2();
        this.closeDisc4();

    }
    closeDisc3() {
        this.open3 = false;
        this.showTopItems3 = false;

    }
     openDisc4() {
        this.showTopItems4 = false;
        this.open4 = !this.open4;
        setTimeout(() => {
            this.showTopItems4 = true;
        }, 250);
        this.closeDisc1();
        this.closeDisc();
        this.closeDisc2();
        this.closeDisc3();


    }
    closeDisc4() {
        this.open4 = false;
        this.showTopItems4 = false;

    }

    isActiveLink(str?) {
        switch (str) {
            case 'Repos': return (this.router.url.includes('/leave') || this.router.url.includes('/recovery'));
            case 'Finance': return (this.router.url.includes('/payslip') || this.router.url.includes('/loan')||this.router.url.includes('/fees'));
            case 'Absences': return (this.router.url.includes('/absence') || this.router.url.includes('/exits'));
            case 'Option':return (this.router.url.includes('/employee-professional-infomations')||this.router.url.includes('/missions'));
            default:
                break;
        }

    }
    setbottomBarItems(){
        if(!this.loanAvalaible)
        {
            this.financeLinks.splice(this.financeLinks.findIndex(e=>e.title==='Prêt'),1)
         }
        if(!this.feesAvaliable)
         {
            this.financeLinks.splice(this.financeLinks.findIndex(e=>e.title==='Frais'),1);
         }
         if(!this.missionAvaliable)
         {
            this.optionsLinks.splice(this.optionsLinks.findIndex(e=>e.title==='Missions'),1);
         }
    }
    payIndex(){
        return this.financeLinks.findIndex(e=>e.title==='Paie')
    }
    leaveIndex(){
        return this.leaveLinks.findIndex(e=>e.title==='Congés')
    }
    absenceIndex(){
        return this.absencesLink.findIndex(e=>e.title==='Absence')
    }
    missionIndex(){
        return this.optionsLinks.findIndex(e=>e.title==='Infos')
    }
}