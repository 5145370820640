<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container>
  <vertical-layout-1></vertical-layout-1>
</ng-container>

<app-fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
  <fuse-theme-options></fuse-theme-options>
</app-fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->