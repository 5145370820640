/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { RequestInterceptorService } from "./request-interciptor.service";
import { Preferences } from "@capacitor/preferences";
import {
    PushNotifications,
    Token,
    ActionPerformed,
} from "@capacitor/push-notifications";
import { SnackBarService } from "./snack-bar.service";
import { Router } from "@angular/router";
import { StorageUtils } from "../shared/storage-utils";
import { ApiService } from "./api.service";

interface FcmTokenPayload {
    token: string;
    userId: string;
}

@Injectable({
    providedIn: "root",
})
export class PushNotificationsService {
    errorSnackBarClass = "error-snackbar";
    succesSnackBarClass = "success-message-snackbar";
    infoSnackBarClass = "info-message-snackbar";

    constructor(
        private requestInterceptorService: RequestInterceptorService,
        private apiService: ApiService,
        private snackBarService: SnackBarService,
        private router: Router
    ) {}

    async initPushNotifications() {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive !== "granted") {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== "granted") {
            this.snackBarService.showSnackBar(
                "errors.pushNotificationsUserDeniedPermissions",
                this.errorSnackBarClass
            );
        }

        await PushNotifications.register();

        PushNotifications.addListener("registration", async (token: Token) => {
            const existingToken = await this.getToken();

            const userId = StorageUtils.getUser()?.id;
            if (!userId) {
                return;
            }
            this.updateTokenIfNecessary({
                token,
                existingToken,
            });
        });

        PushNotifications.addListener("registrationError", (error) => {
            this.snackBarService.showSnackBar(
                "errors.pushNotificationsRegistrationError",
                this.errorSnackBarClass
            );
        });

        PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (action: ActionPerformed) => {
                this.router.navigate(["pointing", "plannings"]);
            }
        );
    }

    private updateTokenIfNecessary({
        token,
        existingToken,
    }: {
        token: Token;
        existingToken: string;
    }): void {
        if (token.value !== existingToken) {
            this.sendPushNotificationsToken(token, !!existingToken);
        }
    }

    sendPushNotificationsToken(token: Token, isUpdate: boolean): void {
        const user = StorageUtils.getUser();
        const userId = user?.id;

        if (!userId) {
            return;
        }

        const fcmToken: FcmTokenPayload = { token: token.value, userId };

        const action$ = this.sendPushNotificationsTokenToBackend(fcmToken);

        action$.subscribe({
            next: () => {
                this.setToken({ value: token.value });
            },
            error: (err) => {
                this.removeToken();
                this.snackBarService.showSnackBar(
                    "errors.sendNotifError",
                    this.errorSnackBarClass
                );
            },
        });
    }

    getPushNotificationsTokenFromBackend(userId: number): Observable<any> {
        const url = `${environment.ENDPOINTS.UAA_URL}/users/${userId}/fcm-tokens`;
        return this.apiService.httpGet(url);
    }

    sendPushNotificationsTokenToBackend(
        fcmToken: FcmTokenPayload
    ): Observable<any> {
        const tokenParam = `?token=${fcmToken.token}`;
        const url = `${environment.ENDPOINTS.UAA_URL}/employees/${fcmToken.userId}/fcm-tokens${tokenParam}`;
        return this.apiService.httpPost(url, fcmToken);
    }

    updatePushNotificationsTokenToBackend(
        fcmToken: FcmTokenPayload
    ): Observable<any> {
        // TODO on peut utliser cette endpoint dans le cas ou on veut mettre à jour le token
        const url = `${environment.ENDPOINTS.UAA_URL}/users/${fcmToken.userId}/fcm-tokens`;
        return this.apiService.httpPut(url, fcmToken);
    }

    async setToken({ value }: { value: string }) {
        await Preferences.set({
            key: "pushNotificationsToken",
            value,
        });
    }

    async getToken() {
        const { value } = await Preferences.get({
            key: "pushNotificationsToken",
        });
        return value;
    }

    async removeToken() {
        await Preferences.remove({ key: "pushNotificationsToken" });
    }
}
