content {
    position: relative;
    display: flex;
    z-index: 1;
    flex: 1 0 auto;

    > *:not(router-outlet) {
        display: flex;
        flex: 1 0 auto;
        width: 100%;
        min-width: 100%;
    }
}