quick-panel {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    z-index: 99;
    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
    .mat-slide-toggle-content {
        flex: 1;
    }
}
